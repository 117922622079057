import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Sidebar = props => {
  const menuData = useStaticQuery(graphql`
    {
      allSanityPage {
        nodes {
          title
          slug {
            current
          }
          menuHeading {
            heading
            order
            id
          }
        }
      }
    }
  `)

  const pageNodes = menuData.allSanityPage.nodes
  const headingsObject = {}

  // remove slow glass as cp and those without headings
  const curatorialString = "slow glass as curatorial practice"
  const pageNodesForMenu = pageNodes.filter(
    pageNode =>
      pageNode.menuHeading !== null &&
      pageNode.title.toLowerCase() !== curatorialString
  )

  const curatorialPage = pageNodes.find(
    pageNode => pageNode.title.toLowerCase() === curatorialString
  )

  pageNodesForMenu.forEach(pageNode => {
    headingsObject[pageNode.menuHeading.id] = {
      headingTitle: pageNode.menuHeading.heading,
      headingOrder: pageNode.menuHeading.order,
      pageArray: [],
    }
  })

  pageNodesForMenu.forEach(pageNode => {
    headingsObject[pageNode.menuHeading.id].pageArray.push({
      pageTitleInner: pageNode.title,
      slug: pageNode.slug.current,
    })
  })

  const sortedHeadingsArray = Object.values(headingsObject)
  sortedHeadingsArray.sort((a, b) => a.headingOrder - b.headingOrder)

  const underlineString = listTitle => {
    const output = listTitle === props.currentPageTitle ? "underline" : ""
    return output
  }

  return (
    <div className="">
      <Link to={`/${curatorialPage.slug.current}/`}>
        <a
          className={`px-6 pb-2 block text-white font-normal ${underlineString(
            curatorialPage.title
          )}`}
          href="/"
        >
          {curatorialPage.title.toUpperCase()}
        </a>
      </Link>
      {sortedHeadingsArray.map(heading => {
        // works in place
        heading.pageArray.sort((a, b) => {
          return a.pageTitleInner.localeCompare(b.pageTitleInner)
        })

        return (
          <>
            <li className="px-6 pb-1 block text-white font-normal" href="/">
              {heading.headingTitle.toUpperCase()}
            </li>
            {heading.pageArray.map(page => (
              <>
                <Link to={`/${page.slug}/`}>
                  <a
                    className={`px-10 pb-1 block text-white font-light ${underlineString(
                      page.pageTitleInner
                    )}`}
                    href="/"
                  >
                    {page.pageTitleInner}
                  </a>
                </Link>
              </>
            ))}
          </>
        )
      })}
    </div>
  )
}

export default Sidebar
