import Header from "./header"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"

const DarkHeader = props => {
  const queryData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sqLogoWhite.png" }) {
        childImageSharp {
          fluid(maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Header imageData={queryData} height={props.height} bannerColour="black" />
  )
}

export default DarkHeader
