import React from "react"
import Sidebar from "../components/sidebar"
import Helmet from "react-helmet"
import DarkHeader from "../components/headers/darkHeader"
import FormattedParagraphs from "../components/formattedParagraphs"

export default function SimpleTemplate({ pageContext: { pageContent } }) {
  const headerHeight = 32

  return (
    <>
      <Helmet>
        <body className="bg-black" />
      </Helmet>
      <body className="bg-black">
        <DarkHeader height={headerHeight} />
        <div className={`pt-${headerHeight} pb-64`}>
          <div className="flex  md:flex-row justify-center">
            <div className="hidden md:inline w-3/12 h-12 "></div>
            <div className="w-10/12 md:w-6/12 flex">
              <FormattedParagraphs
                classes="text-white"
                blocks={pageContent._rawMainContent}
              />
            </div>
            {/* for big screens */}
            <div
              style={{ top: 128 }}
              className="hidden md:inline w-3/12 h-full z-10 sticky"
            >
              <Sidebar currentPageTitle={pageContent.title} />
            </div>
          </div>
          {/* For small screens */}
          <div className="md:hidden z-10 w-10/12 py-8">
            <Sidebar currentPageTitle={pageContent.title} />
          </div>
        </div>
      </body>
    </>
  )
}
